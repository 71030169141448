<template>
  <div class="standard-page">
    <!-- Show a card -->
    <v-card class="box-shadow-soft rounded rounded-bl-0 rounded-br-0 px-3 pt-3 pb-4">
      <!-- Header and Actions -->
      <div class="d-flex flex-wrap flex-md-nowrap justify-space-between mb-8">
        <!-- Show tabs options -->
        <v-tabs
          v-model="selectedTab"
          background-color="transparent"
        >
          <v-tab
            v-for="(tab, index) in tabOptions"
            :key="tab.text"
          >
            <v-icon
              left
              :color="index === selectedTab ? 'primary' : null"
            >
              {{ tab.icon }}
            </v-icon>

            {{ tab.text }}
          </v-tab>
        </v-tabs>

        <!-- Actions -->
        <div class="d-flex flex-grow-1 flex-md-grow-0 flex-wrap flex-md-nowrap align-center justify-center justify-md-end mt-4 mt-md-0">
          <!-- Delete Reports -->
          <v-btn
            v-if="selectedItems.length"
            depressed
            class="mr-3"
            color="primary"
            :disabled="isMakingRequest"
            :class="{ 'shadow--primary': !isMakingRequest }"
            @click="shouldShowDeleteDialog = true"
          >
            <v-icon left>delete</v-icon>
            Delete {{ selectedItems.length > 1 ? selectedItems.length + " Groups" : "Group" }}
          </v-btn>

          <!-- Create New Report -->
          <v-btn
            depressed
            color="primary"
            class="shadow--primary"
            :to="{ name: 'InfluencerGroupsCreate' }"
          >
            <v-icon left>add</v-icon>
            New Group
          </v-btn>
        </div>
      </div>

      <!-- Show the search filters -->
      <v-row>
        <!-- Show the selectors -->
        <v-col
          cols="12"
          sm="6"
          lg="8"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <platform-selector
                v-model="selectedPlatforms"
                label="Platforms"
                small
                outlined
                full-width
                allow-multiple
                :show-snapchat="false"
                :show-linkedin="false"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-spacer />

        <!-- Show the input -->
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="searchQuery"
            label="Search"
            placeholder="Group name"
            outlined
            dense
            hide-details
            clearable
            append-icon="search"
            @keypress.enter="currentPage = 1"
            @click:append="currentPage = 1"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- When there's no response data -->
      <template v-if="tableItems.length === 0 && !isMakingRequest">
        <div style="max-width: 360px" class="mx-auto">
          <lottie-animation
            :loop="false"
            :speed="0.5"
            file="5081-empty-box.json"
          />
        </div>

        <div class="d-flex justify-center">
          <v-btn
            text
            color="primary"
            :to="{ name: 'InfluencerGroupsCreate' }" class="no-decoration"
          >
            Create your first influencer group!

            <v-icon right>
              arrow_forward
            </v-icon>
        </v-btn>
        </div>
      </template>
    </v-card>

    <!-- Data Table -->
    <template v-if="tableItems.length">
      <v-data-table
        v-model="selectedItems"
        :headers="tableHeaders"
        :items="tableItems"
        :server-items-length="response[tabOptions[selectedTab].value].total"
        :page="currentPage"
        :items-per-page="queryPerPage"
        @update:page="currentPage = $event"
        @update:items-per-page="queryPerPage = $event"
        :show-select="shouldShowSelect"
        selectable-key="id"
        class="box-shadow-soft influencer-groups-index-table"
        :mobile-breakpoint="100"
      >
        <!-- Hide the checkbox to select all from header -->
        <template v-slot:header.data-table-select></template>

        <!-- The header for actions column -->
        <template v-slot:header.actions>
          <span class="d-block text-right">
            Actions
          </span>
        </template>

        <!-- For Platform -->
        <template v-slot:item.platforms="{ item }">
          <div class="d-flex">
            <v-img
              v-for="platform in item.platforms"
              :key="platform"
              height="30"
              width="30"
              class="mr-2"
              max-width="30"
              :src="platformIconMap[platform]"
            />
          </div>
        </template>

        <!-- For influencers count -->
        <template v-slot:item.influencers_count="{ item }">
          <span :title="item.influencers_count">
            {{ nFormatter(item.influencers_count) }}
          </span>
        </template>

        <!-- For actions -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-btn
              small
              depressed
              color="primary"
              @click="$router.push({ name: 'InfluencerGroupsView', params: { uuid: item.uuid } })"
            >
              View

              <v-icon right>
                navigate_next
              </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>

    <!-- Confirm delete dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="500"
    >
      <v-card v-if="selectedItems.length">
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3">
            delete
          </v-icon>

          Confirm your action
        </v-card-title>

        <v-card-text class="pt-4">
          You are about to delete <b>{{ selectedItems.length }} {{ selectedItems.length > 1 ? "groups" : "group" }}</b> <br />
          This cannot be undone!
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :disabled="isMakingRequest"
            :loading="isMakingRequest"
            @click="handleDelete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector")

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerGroupsIndex",

  // Register children components
  components: {
    LottieAnimation,
    PlatformSelector
  },

  // Define local data variables
  data: () => ({
    // Whether or not the request is being made
    isMakingRequest: false,

    // The currently selected tab item
    selectedTab: 0,

    // To handle the delete process
    selectedItems: [],
    shouldShowDeleteDialog: false,
  }),

  // Define readonly data variables
  computed: {
    /**
     * Get the tab options
     *
     * @returns {Array}
     */
    tabOptions() {
      const items = [
        {
          icon: "person",
          text: "Created By Me",
          value: "created_by_user",
          condition: true
        },
        {
          icon: "people",
          text: "Created By Team",
          value: "created_by_team",
          condition: this.$store.getters["auth/isPartOfTeam"]
        },
        {
          icon: "folder_shared",
          text: "Shared with me",
          value: "shared_with_user",
          condition: true
        }
      ]

      return items.filter((item) => item.condition)
    },

    /**
     * Get the table headers
     *
     * @returns {Array}
     */
    tableHeaders() {
      return [
        // {
        //   text: "#",
        //   value: "index",
        //   sortable: false,
        //   filterable: false,
        // },
        {
          text: "",
          value: "platforms",
          sortable: false
        },
        {
          text: "Name",
          value: "name",
          sortable: false,
        },
        {
          text: "Influencers",
          value: "influencers_count",
          align: "right",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false,
        }
      ].filter((item) => {
        // If the hideIfNoResults is not set or is false
        if (!item.hideIfNoResults) {
          // Show it
          return true
        }

        // Otherwise, check if any of the items have this value and if the value exists, it's not an empty array
        return this.tableItems.some((tableItem) => tableItem[ item.value ] && tableItem[ item.value ].length)
      })
    },

    /**
     * If even single item is selectable
     *
     * @returns {Boolean}
     */
    shouldShowSelect() {
      return this.tableItems.some((item) => item.isSelectable)
    },

    /**
     * Get API response from the Vuex store
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters["influencerGroups/response"]
    },

    /**
     * Get the queries from Vuex store
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["influencerGroups/query"]
    },

    /**
     * Get the current page number in pagination
     */
    currentPage: {
      get() {
        return this.query.page
      },

      set(value) {
        this.$store.dispatch("influencerGroups/updateQuery", {
          key: "page",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the number of items per page
     */
    queryPerPage: {
      get() {
        return this.query.perPage
      },

      set(value) {
        this.$store.dispatch("influencerGroups/updateQuery", {
          key: "perPage",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected platform
     */
    selectedPlatforms: {
      get() {
        return this.query.platforms
      },

      set(value) {
        this.$store.dispatch("influencerGroups/updateQuery", {
          key: "platforms",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the search query
     */
    searchQuery: {
      get() {
        return this.query.search
      },

      set(value) {
        // If the value is same
        if (this.query.search === value) return

        this.$store.dispatch("influencerGroups/updateQuery", {
          key: "search",
          value
        })
      }
    },

    /**
     * Get the data from vuex state
     *
     * @returns {Array}
     */
    tableItems() {
      return this.response[this.tabOptions[this.selectedTab].value].data
        .map((item, index) => ({
          ...item,
          index: index + 1,
          isSelectable: this.shouldShowDeleteButton(item, this.tabOptions[this.selectedTab].value)
        }))
    },
  },

  // Define local method functions
  methods: {
    /**
     * Whenever the select value for status is changed
     *
     * @returns {void}
     */
    async fetchItems() {
      // If a request is already being made
      if (this.isMakingRequest) {
        // Don't do anything
        return
      }

      // Show a loader
      this.isMakingRequest = true

      // Make the network request
      await this.$store.dispatch("influencerGroups/fetchItems")

      // Hide the loader
      this.isMakingRequest = false
    },

    /**
     * Make network request to delete each selected row
     *
     * @returns {void}
     */
     async handleDelete() {
      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Go through each selected items
      for (const item of this.selectedItems) {
        try {
          await axios({
            url: `/api/influencer-groups/${item.id}`,
            method: "DELETE"
          })

          // Sync the model items
          this.fetchItems()
        } catch (error) {
          logger({ type: "InfluencerGroupsIndex Delete Error", error })

          this.$store.dispatch("toasts/add", { text: "An error occurred!" })
        }
      }

      // Once all requests have been made, remove the loader
      this.$store.dispatch("loaders/remove", loaderId)

      // Reset the local state
      this.isMakingRequest = false
      this.shouldShowDeleteDialog = false

      // Mark selected items as empty
      this.selectedItems = []

      // Refresh the data
      this.fetchItems()
    },

    /**
     * Whether or not to show the delete button
     *
     * @param {Object} item
     * @param {String} tab
     * @returns {Boolean}
     */
    shouldShowDeleteButton(item, tab) {
      // Go through the type values
      switch (tab) {
        case "created_by_user":
          return true

        case "created_by_team":
          return item.team_behaviour === "read-write"

        case "shared_with_user":
          return item.can_write === true
      }
    }
  },

  /**
   * As soon as the component is ready
   *
   * @returns {void}
   */
  created() {
    // Dispatch fetch method for influencer groups models from API
    this.fetchItems()
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  width 10em
</style>

<style lang="stylus">
.influencer-groups-index-table thead tr th, .influencer-groups-index-table tbody tr td
  white-space nowrap !important
</style>
